import { pathWithParams } from 'utils/url/pathWithParams';

export type ImportedListingSource =
  | 'DONORS_CHOOSE'
  | 'KIVA'
  | 'NYCPARKS'
  | 'GLOBAL_GIVING'
  | 'VOLUNTEERMATCH'
  | 'VOLUNTEERGOV'
  | 'MOVE_ON'
  | 'POINTS_OF_LIGHT'
  | 'AARP'
  | 'DO_SOMETHING'
  | 'NEWYORKCARES'
  | 'CITIZENCONNECT'
  | 'GRAPEVINE'
  | 'TRANSFORMA'
  | 'IDEALIST';

export function getImportedListingUrl(
  url: string,
  source: ImportedListingSource | undefined | null,
) {
  if (source && source === 'DO_SOMETHING') {
    return pathWithParams(url, {
      utm_medium: 'referral',
      utm_source: 'partner',
      utm_campaign: 'idealist_vcredit',
      utm_content: 'vcredit',
    });
  }

  return pathWithParams(url, {
    utm_medium: 'referral',
    utm_source: 'idealist',
    utm_campaign: 'action_opportunities',
  });
}
