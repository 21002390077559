import { configString } from 'config/appConfigUtils';
import { SortType } from 'store/mainSearch/MainStoreMainSearchState';

export function searchAlgoliaIndex(sort: SortType) {
  switch (sort) {
    case 'relevance':
      return configString('algolia', 'siteIndexName');
    case 'newest':
      return configString('algolia', 'sitePublishedDescIndexName');
  }
}
