import styled from 'styled-components';

import { Box } from 'components/Box';
import { colors, cssBreakpoints, shadows, transition } from 'theme/theme';
import { isDefined } from 'utils/functional';

export const SmallTile = styled(Box)<{ $blueBorder?: boolean }>`
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: ${shadows.low};
  word-wrap: break-word;
  ${(props) => (props.bg ? '' : 'background: white')};
  ${(props) =>
    isDefined(props.p)
      ? ''
      : `
  padding: 20px;
  @media all and (min-width: ${cssBreakpoints.smUp}) {
    padding: 30px;
  }
  `}
  ${(props) =>
    props.$blueBorder &&
    `
    position: relative;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      transform: translateY(5px);
      width: 100%;
      height: 100%;
      background: ${colors.brandBlue};
      transition: all ${transition};
    }
  `}
`;
